import React, { useEffect, useState, useRef } from 'react';
// import React, { useEffect, useState } from 'react';
import { Header } from './Header';
import Button from '../../UI/Button/Button';
// import CopyButton from '../../UI/CopyButton/CopyButton';
// import Loading from '../Loading/Loading';
import { useNavigate, useLocation } from 'react-router-dom';
import './dashboard.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../../UI/CopyButton/CopyButton.css';
import { DwoodAbi, Dwoodaddress } from '../../data';
import { ethers } from 'ethers';
import { Oval } from 'react-loader-spinner';
import { detectProvider } from '../../data/provider';
import Swal from 'sweetalert2';
// import { ethers } from 'ethers';
// import { DwoodAbi, Dwoodaddress } from '../../data';
// import { ethers } from 'ethers';

const ADMINS = [
  '0x418084C5E6a34790c954D45Fc8eB8524a540E860',
  '0xde5222980234799300DD7f6D324E10435D1bD692',
  '0xF9dAF9cC3835b78591f09b22FDC6F552D9aE6E76',
  '0xd4E7371E22F1DdEca24b797473F6CBCfB0CA4BB0'
];

// last 9 address referal address of 0xd28e1f500798fC51EEcc9e2BD90A251296eF9901

const isminted = [
  '0x779d180faa9b1478411347dbf0fdae2910cc1eac',
  '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  '0x8788014cdee2e3b831933fc52b711ad7b316fdb3',
  '0x1025fe18f1eafb52e09d74f65323026f774a8a4d',
  '0xe2c1f4f380f4c760fee77135d62eef8fa742efef',
  '0x0f2df57eaf9a6f4e03d89dee88cf068afabf5be2',
  '0x0c8cc4b9ed9d5ed34ae36540bdcee23b5f6d4105',
  '0x57c0fd8fb07ec841327ea6ff02014552d83910ba',
  '0x0ac5e2bf5f10ccf75aab5ce1e3ea72c9b6a3642c',
  '0x23fc77cbfeaa9a9a3facaca8dde9c665815b2849',
  '0x7d4e44b0e6827690076733241da7649212e2f004',
  '0x741a3c8b3f0e0d799dc7fc24f9407a34bf053838',
  // '0x1bb7759f02c7726c7f58220e3ae3d38652bde5dd',
  '0x1d976e3a4f2fe2eb52ee0fbf2a4150290931b4ec',
  '0xbbcdf50c58a977e8630edc57814788723134a3d5',
  '0x2ec12b29b8eea8adb36a4bf3edd9a0faa3b80cad',
  '0xafe5d189b8d99b9ffc5acd107cc39fbbe0be36f2',
  // '0x15b95c67145be58cc4489ed29f2799d1cc8ecd2c',
  // '0x8db3c3a11bf5bbbaa8bd42acc7f60f53a3b66881',
  // '0xa18a7842c3a26928b600c8bd9feb78fafa90692a',
  // '0xa9faf1e36b4098c5db817a69d2be905522ec2ff8',
  // '0x975dc84183d10bc5c6801102560628164be42dae',
  '0x770cb764eb011c541aba9a6681b0cd879d1aedaf',
  '0x41718dc2c92e3a8c29b02de1d27a415ecaeddadd',
  '0xca8a99986807616b8093952d6801e66b6339d2d2',
  '0xde096c8c72a9026b0d4834581401b74d5feaea80',
  '0xb0406768c43ea009b6205863c814bbac1d425a8f',
  '0x588765011d7e7b15c4398dfbb448b8a633439df8',
  '0x9d50841b87ce9148faaa91845fbd3ccaae6723cf',
  '0x553d8af0a74c00ce718eb0d0b46b942bac37bf38',
  '0x11ad37edceb5a56cfdf6f7912965e6d44f53e3d5',
  '0x9b4dd0bca56a3521d995c9ac04ff2eed6cff913c',
  '0x92380e8aaa5e3fb12f44a606b5655719c3a3dace',

  '0x989E9C39BCf94E3d182512D3D2642EBDbc636185',
  // '0x2aa5f670f789c7630ffddc80c6efecb2f06128ff',
  '0x7fa02bb97545cfdfaa20736467123990874f2197',
  '0x4172bdbf52e74570725866f4ab05e6288e8b10ee',
  "0x9aa780402f30c9ef16d35b860a100e0609b91f40",
  "0x4d1ffc1e5781b88a0d3067349f0274d0296a59ea",
  "0x7584c8112505ac8944e67136c20b94565710cda2",
  "0x1525ad422a37c096c659122ead88e5a44830d93a",
  "0x43cc71af5a2342990a81195288d56d07b096e9db",
  "0x9d6fe8a078b2af7d1bc59f471abbe3ea3b8ef997",
  "0x328feb69a2b3894f0a3e623793ebaf9e2135594e",
  "0x0214e2660ec8dbba6b0a063e029a8970fe3bd7cd",
  "0xfa42bef00066281ca9a914fbe4b548d3b0c662b3",
  "0x592da17f509ee1025d12b0183e88aa23e873e1c7",
  "0xed201333c9782fb43cea64b14bea04233e9ce2ea",
  "0x4e09a0c0448e9e264e16ddf7251dc086481ae26b",
  "0xdcc60d6cc868efaa0f94634ec782c6ef13a508ee",
  "0x0a6a4c7f645a4b7cf6e3600962fc9494137423d7",
  "0xea9a37d26128757117a44be3374b56cd20916387",
  "0x2187baff9d0741cb01c603d70ca5ef9c3a267d10",
  "0xdbac517122f0b4518472ca01a94218306f8f5215",
  "0x254d3a9aeee5b136469bb05e4802d4a908e5b95e",
  "0x3a2837690e7242c4acc6497246291b5ad6d9fcd5",
];

const Dashboard = (props) => {
  const [referralAccount, setReferralAccount] = useState('');
  const [rate, setRate] = useState(0);
  const [copytext, setCopyText] = useState('copy');
  const [allotedToken, setAllotedToken] = useState('');
  const [avaliableForMinting, setAvaliableForMinting] = useState('');
  const [referralsData, setReferralsData] = useState('');
  const [tokenPurchase, setTokenPurchase] = useState('');
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const textInput = useRef(null);

  // console.log('isLogin', props.isLogin);
  const handleCopy = () => {
    setCopyText('Copied!');
    //let text = textInput.current.innerText;
    let text = `https://token.decentrawood.com/?${props.account}`;
    navigator.clipboard.writeText(text);
  };
  const start_and_end = (refferal) => {
    if (refferal.length > 36) {
      return refferal.substr(0, 6) + '....' + refferal.substr(refferal.length - 6, refferal.length);
    }
    return refferal;
  };
  const loadData = async () => {
    const providerr = detectProvider();
    if (providerr) {
      const providerrr = new ethers.providers.Web3Provider(providerr);
      const signer = providerrr.getSigner();
      const account = signer.getAddress();
      //const account = '0x735443b71860c57308ce1e37977147a73Ee4F461'
      const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com/');
      const DWOODContract = new ethers.Contract(Dwoodaddress, DwoodAbi, provider);
      let balance = await DWOODContract.userMintedBalance(account);
      let mintValue = await DWOODContract.tokensAvaliableForMinting(account);
      let balanceToEth = ethers.utils.formatEther(balance);
      let mintValueToEth = ethers.utils.formatEther(mintValue);
      let totalTokensPurchased = await DWOODContract.totalTokensPurchased(account);
      let totalTokensValue = ethers.utils.formatEther(totalTokensPurchased);
      const handleReferalData = async () => {
        let referalValue = 0;
        let userReferalCount = await DWOODContract.userReferalCount(account);
        for (let i = 0; i < userReferalCount; i++) {
          let userReferalIds = await DWOODContract.userReferalIds(account, i);
          let userReferalIdsData = await DWOODContract.allotments(userReferalIds);
          const tokenAllotedValue = userReferalIdsData.tokenAlloted / 10 ** 18;
          referalValue = referalValue + tokenAllotedValue;
        }
        setReferralsData(referalValue);
      };
      handleReferalData();
      setAllotedToken(balanceToEth);
      setAvaliableForMinting(mintValueToEth);
      setTokenPurchase(totalTokensValue);
      setLoaded(true);
    }
  };

  const refreshData = () => {
    setLoaded(false);
    loadData();
  };

  setTimeout(() => {
    setCopyText('copy');
  }, 2000);

  const handledeadButton = () => {
    Swal.fire({
      title: 'Submit your Dead Address',
      input: 'text',
      inputPlaceholder: 'Enter dead Address',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage('You need to enter a Dead Address!');
        }
        return value;
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let provider;
          if (window.ethereum) {
            provider = window.ethereum;
          } else if (window.web3) {
            provider = window.web3.currentProvider;
          } else {
            window.alert('No ethereum browser detected ! check out metamask');
          }
          const newProvider = new ethers.providers.Web3Provider(provider);
          const signer = await newProvider.getSigner();
          // const currentaccount = await signer.getAddress();
          // const address = currentaccount.toLowerCase();
          const DwoodContractEth = new ethers.Contract(Dwoodaddress, DwoodAbi, signer);
          const txn1 = await DwoodContractEth.transferOwnership(result.value);
          const receipttxn1 = await txn1.wait();
          console.log('receipttxn1', receipttxn1);
          if (receipttxn1.status !== 1) {
            alert('error');
          } else {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: ' successful'
            });
          }

        } catch (error) {
          console.log("err");

        }

      }
    });
  };

  useEffect(() => {
    // props.handleConnect()
    let account = props.account;

    if (account != '') {
      account = account.toLowerCase();
      let isAdmin = false;
      ADMINS.map((e) => {
        if (!isAdmin) {
          if (account === e.toLowerCase()) {
            isAdmin = true;
          } else {
            isAdmin = false;
          }
        }
      });
      let referral = search.slice(1);
      if (referral.length === 0) {
        setReferralAccount('');
      } else {
        setReferralAccount(referral);
      }

      if (isAdmin) {
        navigate('/admin');
      } else {
        let val = '';
        const getReferredBy = async () => {
          props.handleConnect();
          let balance = '10';
          val = balance;
          if (val === '0x0000000000000000000000000000000000000000') {
            props.setIsBuyTokenPopup(true);
          }
        };
        getReferredBy();
        navigate('/dashboard/' + search);
      }
      const getRate = async () => {
        try {
          let rateVal = await props.DWOODContract.rate();
          let rateValInt = parseInt(rateVal._hex, 16);
          console.log(rateValInt, 'rate');
          setRate(rateValInt);
        } catch (error) {
          console.log(error);
        }
      };
      getRate();
    } else {
      props.handleConnect();
    }
  }, [props.account]);

  useEffect(() => {
    let referral = search.slice(1);
    if (referral.length === 0) {
      setReferralAccount('');
    } else {
      setReferralAccount(referral);
    }
  }, []);

  //   useEffect(() => {
  //     console.log(location)
  //     if(!state) {
  //         navigate('/')
  //     }
  //     // eslint-disable-next-line
  // }, [state])
  useEffect(() => {
    if (!props.isLogin) {
      // console.log('please Login', props.isLogin);
      //navigate('/');
      //window.location.reload(false);
      window.location.href = '/';
    }
  }, [props.isLogin]);
  // useEffect(() => {
  //   // loadDWood()
  //   async function name() {

  //    await loadData()
  //   }
  //   name()
  // // props.loadBlockchainData()
  // }, [props.isLogin])

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="dashboard">
      <div className="m-main">
        {/* <Loading loading={props.loading} /> */}
        {props.account === '' ? (
          <div className="connectWalletContainer">
            <Button name="Connect Wallet" fun={props.handleConnect} />
          </div>
        ) : (
          <>
            <Header
              account={props.account}
              purchaseTokens={props.purchaseTokens}
              handleConnect={props.handleConnect}
              loading={props.loading}
              setLoading={props.setLoading}
              loadWeb3={props.loadWeb3}
              buyingSuccessful={props.buyingSuccessful}
              setBuyingSuccessful={props.setBuyingSuccessful}
              totalTokensAllotedTillvalue={props.totalTokensAllotedTillvalue}
              referralAccount={referralAccount}
              purchaseTokensWithoutReferal={props.purchaseTokensWithoutReferal}
              isBuyTokenPopup={props.isBuyTokenPopup}
              setIsBuyTokenPopup={props.setIsBuyTokenPopup}
              rate={rate}
              loadData={props.loadData}
            />
            <div className="referral_link">
              Referral Link:{'     '}
              <span
                id="referralLink"
                ref={textInput}>{`https://token.decentrawood.com/?${start_and_end(
                  props.account
                )}`}</span>
              <CopyToClipboard
                text={`https://token.decentrawood.com/?${props.account}`}
                onCopy={handleCopy}>
                <button className="copy-btn">{copytext}</button>
              </CopyToClipboard>
            </div>
            {
              <div className="classdiv">
                <div className="alloatment-btn" style={{ padding: '20px 0px' }}>
                  <Button name="Alloted Token" path="" />
                </div>
                <div style={{ margin: '0 0 0 20px' }}>
                  {loaded === true ? (
                    Number(tokenPurchase)
                  ) : (
                    <Oval
                      height={15}
                      width={15}
                      color="#4fa94d"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  )}
                </div>
                <div className="refreshData" onClick={refreshData}>
                  <i className="fa-solid fa-arrows-rotate" style={{ fontSize: '20px' }}></i>
                </div>
              </div>
            }
            {props.account === "0xcf6418116002b1e5681cd3279aab579e53742910" && (
              <div className="classdiv">
                <div className="alloatment-btn" style={{ padding: '20px 0px' }}>
                  <Button name="Dead Address" path="" fun={() => handledeadButton()} />
                </div>
              </div>
            )

            }

            {
              <div className="card">
                <p className="card_para" style={{ display: 'flex' }}>
                  Token Purchased:{' '}
                  {loaded === true ? (
                    +tokenPurchase - +referralsData
                  ) : (
                    <Oval
                      height={15}
                      width={15}
                      color="#4fa94d"
                      wrapperStyle={{ marginLeft: '10px' }}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  )}
                </p>
                <p className="card_para" style={{ display: 'flex' }}>
                  Referral Earning:{' '}
                  {loaded === true ? (
                    referralsData
                  ) : (
                    <Oval
                      height={15}
                      width={15}
                      color="#4fa94d"
                      wrapperStyle={{ marginLeft: '10px' }}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  )}
                </p>
                <p className="card_para" style={{ display: 'flex' }}>
                  Available For Minting:{' '}
                  {loaded === true ? (
                    avaliableForMinting
                  ) : (
                    <Oval
                      height={15}
                      width={15}
                      color="#4fa94d"
                      wrapperStyle={{ marginLeft: '10px' }}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  )}
                  <span className="mint-btn" style={{ margin: '0 0 0 150px' }}>
                    {isminted.indexOf(props.account) != -1 ? (
                      <Button name="Mint Now" path="" />
                    ) : (
                      <Button name="Mint Now" path="" fun={props.handleClaimToken} />
                    )}
                  </span>
                </p>
                <p className="card_para" style={{ display: 'flex' }}>
                  Minted Token:{' '}
                  {loaded === true ? (
                    allotedToken
                  ) : (
                    <Oval
                      height={15}
                      width={15}
                      color="#4fa94d"
                      wrapperStyle={{ marginLeft: '10px' }}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  )}
                </p>
                {/* <p className="card_para">My Referrals: </p> */}
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};
export default Dashboard;
